/* eslint-disable react-hooks/exhaustive-deps */
// import { Box, NoSsr, useTheme } from "@mui/material";
import React from "react";
// import { Footer } from "../Footer";
import Navbar from "../Navbar";
// import { drawerIsOpenAtom, marketPlaceAtom, sdkAtom } from "@/state/atoms";

import { Poppins } from "next/font/google";
import Footer from "../Footer";
const poppins = Poppins({
  weight: "400",
  subsets: ["devanagari"],
});
interface Props {
  children?: React.ReactNode | React.ReactNode[];
  noSsr?: boolean;
  disablePadding?: boolean;
}
const MainLayout: React.FC<Props> = ({ children }) => {
  // const theme = useTheme();

  const render = () => (
    <div className={poppins.className}>
      <div>
        <Navbar />
        <div className="min-h-screen">{children}</div>
        <Footer />
      </div>
    </div>
  );

  // if (noSsr) {
  //   return <NoSsr>{render()}</NoSsr>;
  // }

  return render();
};

export default MainLayout;
