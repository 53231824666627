/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Divider, Link, Stack, Typography } from "@mui/material";
import { useAddress } from "@thirdweb-dev/react-core";
import { ConnectWallet } from "@thirdweb-dev/react";
import {
  Autocomplete,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useRouter } from "next/router";
import {
  useAuthen,
  useBlockchain,
  useNFTCollection,
} from "@hapchain-nft/hooks";
import style from "@/styles/components/header.module.scss";
import MenuBg from "../../public/assets/images/bars-sort 1.png";
import Image from "next/image";
let handleDelay: any;
function Navbar() {
  const address = useAddress();
  const { push } = useRouter();
  const { search } = useNFTCollection();
  const [open, setOpen] = React.useState(false);
  const { loggedIn, login } = useAuthen();
  const { network } = useBlockchain();
  const [active, setActive] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [txtSearch, setTxtSearch] = React.useState<string>("");
  const [inputValue, setInputValue] = React.useState<string | undefined>(
    undefined
  );
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  const hanldeInputChange = (_event: any, newValue: string | null) => {
    if (newValue && newValue.length >= 3) {
      // setInputValue(newValue);
      console.log(newValue);
      setLoading(true);
      if (handleDelay) {
        clearTimeout(handleDelay);
      }
      handleDelay = setTimeout(() => {
        setTxtSearch(newValue);
        search(newValue).then((results) => {
          setOptions(results);
          console.log(results);
          setLoading(false);
        });
      }, 500);
    } else {
      console.log("clear");
      setOptions([]);
      setLoading(false);
    }
  };
  const handleValueChange = (_event: any, newValue: any) => {
    if (newValue && newValue.type === "Collections") {
      push(`/collection/${newValue.contractAddress}`);
    }
    if (newValue && newValue.type === "Accounts") {
      push(`/users/${newValue.walletAddress}`);
    }
    if (newValue && newValue.type === "Items") {
      push(`/collection/${newValue.contractAddress}/${newValue.tokenId}`);
    }
  };

  const handleClearSearch = () => {
    setInputValue("");
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <nav
        style={{
          zIndex: 1000,
        }}
        className="fixed top-0 left-0 w-full bg-navbar backdrop-filter backdrop-blur-lg navbar"
      >
        <div className="container mx-auto py-1 flex items-center justify-between">
          <Link
            href="/"
            className="hidden md:block"
            sx={{
              textDecoration: "none",
            }}
          >
            {/* <Image src={Logo.src} alt="logo" width={335} height={66} /> */}
            <Typography
              variant="h6"
              component="div"
              className="hidden md:block"
              sx={{ flexGrow: 1, textDecoration: "none" }}
            >
              NFT Marketplace
            </Typography>
            <Typography
              variant="h6"
              component="div"
              className="md:hidden"
              sx={{ flexGrow: 1, textDecoration: "none" }}
            >
              NFT
            </Typography>
            <Typography
              variant="body2"
              className="hidden md:block"
              sx={{ flexGrow: 1 }}
            >
              Carbon credit Certificate
            </Typography>
            <Typography
              variant="body2"
              className="md:hidden"
              sx={{ flexGrow: 1 }}
            >
              Carbon
            </Typography>
          </Link>

          <div className="flex gap-5 items-center justify-between md:w-fit w-full">
            <Autocomplete
              className="w-full md:w-96"
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="md:w-96 sm:w-80 w-full bg-red"
                  sx={{
                    borderRadius: 30,
                  }}
                  margin="normal"
                  variant="outlined"
                  placeholder="Search items, collections and accounts"
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      backgroundColor: "rgba(255, 255, 255, 0.6)",
                      borderRadius: "15px",
                    },
                    startAdornment: (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    ),
                    endAdornment: (
                      <>
                        {inputValue && (
                          <IconButton onClick={handleClearSearch}>
                            <ClearIcon />
                          </IconButton>
                        )}
                      </>
                    ),
                  }}
                />
              )}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              loading={loading}
              selectOnFocus
              freeSolo
              disableClearable
              clearOnBlur
              handleHomeEndKeys
              options={options}
              groupBy={(option) => option.type}
              onChange={handleValueChange}
              onInputChange={hanldeInputChange}
              renderOption={(props, option) => {
                // Customize how each option is rendered in the dropdown list
                return (
                  <li {...props}>
                    <Stack direction={"column"} gap={1} width={"100%"}>
                      <Typography component={"h6"}> {option.name}</Typography>
                      {option.items_traits &&
                        option.items_traits.length > 0 &&
                        option.items_traits
                          .filter((f: any) =>
                            f.trait_value
                              .toLocaleLowerCase()
                              .includes(txtSearch.toLocaleLowerCase())
                          )
                          .map((trait: any, index: any) => (
                            <Stack direction={"row"} gap={1} key={index}>
                              <Typography
                                variant={"caption"}
                                component={"span"}
                              >
                                {trait.trait_type}:
                              </Typography>
                              <Typography
                                variant={"caption"}
                                component={"span"}
                              >
                                {trait.trait_value}
                              </Typography>
                            </Stack>
                          ))}
                      <Divider />
                    </Stack>
                  </li>
                );
              }}
              // getOptionLabel={(option) => option.name}
              getOptionLabel={(option) => {
                // Modify the getOptionLabel function if needed, or keep it as it is
                if (option.type === "Collections") {
                  return `${option.name} ${option.contractAddress}`;
                } else if (option.type === "Accounts") {
                  return `${option.displayName || ""} ${option.walletAddress}`;
                } else if (option.type === "Items") {
                  // Concatenate name and trait_value for better matching
                  const nameAndTraitValue = [
                    option.name,
                    ...option.items_traits.map(
                      (trait: any) => trait.trait_value
                    ),
                  ];
                  return nameAndTraitValue.join(" "); // Modify the separator as needed
                } else {
                  return "";
                }
              }}
            />
            <div className="hidden md:flex items-center gap-10 text-black-900">
              {network && network.chainId === 373737 && (
                <Link
                  href="https://faucet.hap.land"
                  sx={{
                    color: "black",
                  }}
                >
                  Faucet
                </Link>
              )}
              <Link
                href="https://carbonlist.io"
                sx={{
                  color: "black",
                }}
              >
                Official site{" "}
              </Link>
              <ConnectWallet theme="light" />
            </div>

            <IconButton
              className="md:hidden"
              onClick={() => {
                setActive(!active);
              }}
            >
              <Image src={MenuBg.src} alt="menu" width={27} height={27} />
            </IconButton>

            {address && (
              <div className="hidden md:block">
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      push(`/users/${address}`);
                    }}
                  >
                    My Collections
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      push(`/collection/create`);
                    }}
                  >
                    Create
                  </MenuItem>

                  <MenuItem
                    onClick={async () => {
                      if (loggedIn) {
                        push(`/setting`);
                      } else {
                        await login();
                        push(`/setting`);
                      }
                    }}
                  >
                    Setting
                  </MenuItem>
                </Menu>
              </div>
            )}
          </div>
          <div className="md:hidden">{/* Mobile menu */}</div>
        </div>
      </nav>
      <div
        className={
          active ? `${style.nav_mobile} ${style.active}` : style.nav_mobile
        }
      >
        <Stack direction={"column"} spacing={2}>
          <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
            <Typography variant={"h6"}>NFT Carbon credit</Typography>
            <IconButton
              onClick={() => {
                setActive(false);
              }}
            >
              <ClearIcon />
            </IconButton>
          </Stack>
          <Link href="/">Home</Link>
          {network && network.chainId === 373737 && (
            <Link href="https://faucet.hap.land" className="text-black">
              Faucet
            </Link>
          )}
          <Link href="https://carbonlist.io">Official site</Link>
          <Divider />
          <Link href="/users">My Collections</Link>
          <Link href="/collection/create">Create</Link>
          <Link href="/setting">Setting</Link>
          <Divider />
          <ConnectWallet theme="light" />
        </Stack>
      </div>

      {/* Add additional content or components */}
    </>
  );
}

export default Navbar;
