/* eslint-disable @next/next/no-img-element */
import React from "react";

import { Typography } from "@mui/material";

/* eslint-disable react/react-in-jsx-scope */
interface Props {
  children?: React.ReactNode | React.ReactNode[];
  title: string;
}
import bk from "../../public/assets/images/bk_header.svg";

const DefaultContainer = (props: Props) => {
  const { children, title } = props;
  return (
    <div className="flex-1">
      <div
        className={`df-c-bg ${
          title ? "md:pt-[300px]" : "md:pt-[200px]"
        } pt-[180px] h-[80px] flex flex-col justify-center items-center`}
        style={{
          position: "relative",
        }}
      >
        <img
          src={bk.src}
          alt="bk"
          width={"100%"}
          height={"469px"}
          className="w-full h-full"
          style={{
            objectFit: "cover",
            height: "469px",
            position: "absolute",
            top: 0,
          }}
        />
        <Typography
          variant="h1"
          component="div"
          className="mb-10 md:mb-[150px]"
          sx={{ flexGrow: 1, zIndex: 9 }}
        >
          {title}
        </Typography>
      </div>
      <div
        className="min-h-screen md:container md:auto"
        style={{
          position: "relative",
        }}
      >
        {children}
      </div>
    </div>
  );
};
export default DefaultContainer;
