/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="flex flex-col   ">
      {/* <div className="flex align-middle items-center h-full flex-1 md:container md:auto flex-row">
        <div className="flex flex-col flex-1">
          <div className="flex flex-row gap-32 ">
            <div className="flex flex-col">
              <p className="text-xl font-bold">Marketpalce</p>
              <a className="link link-hover">All NFTs</a>
              <a className="link link-hover">Popular item</a>
              <a className="link link-hover">Trending</a>
              <a className="link link-hover">Explore Details</a>
            </div>
            <div className="flex flex-col">
              <p className="text-xl font-bold">Account</p>
              <a className="link link-hover">My Profile</a>
              <a className="link link-hover">My Collection</a>
              <a className="link link-hover">My Activity</a>
              <a className="link link-hover">My Wallet</a>
            </div>
            <div className="flex flex-col">
              <p className="text-xl font-bold">Company</p>
              <a className="link link-hover">My Profile</a>
              <a className="link link-hover">My Collection</a>
              <a className="link link-hover">My Activity</a>
              <a className="link link-hover">My Wallet</a>
            </div>
          </div>
        </div>
      </div> */}
      <div className="h-20 bg-footer">
        <div
          className="md:container md:mx-auto flex flex-row justify-between
        align-middle items-center h-full pl-5 pr-5
        "
        >
          <p className="text-[#fff]">
            Copyright {year}. NFT is powered by HAP land
          </p>
          {/* <div className="flex-row gap-5 flex">
            <a className="link link-hover" target="_blank">
              Privacy Policy
            </a>
            <a className="link link-hover" target="_blank">
              Terms of Condition
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
};
export default Footer;
